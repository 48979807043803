import React from 'react';

import Advertising from '../../../../components/advertising/advertising';
import { namespace, SkyBannerPropTypes } from './constants';
import { withComponentHandler } from '../../../../hocs/with-component-handler';

/**
 * SkyBanner component
 *
 * @param {Object} props - Component properties
 * @param {string} props.google_ad - Google ad identifier
 * @param {Object} props.segmentation - Segmentation data
 * @param {boolean} props.personal_data_ads_denied - Flag indicating if personal data ads are denied
 * @param {string} props.slot_id - Slot identifier
 * @param {string|null} [props.viewport_margin=null] - Viewport margin
 * @param {string|null} [props.title=null] - Title
 * @returns {JSX.Element} The rendered component
 */

const SkyBanner = ({
  google_ad,
  segmentation,
  personal_data_ads_denied,
  slot_id,
  viewport_margin = null,
  title = null,
}) => (
  <div className={`${namespace}__display`}>
    <Advertising
      slotId={slot_id}
      googleAd={google_ad}
      personalDataAdsDenied={personal_data_ads_denied}
      viewportMargin={viewport_margin}
      segmentation={segmentation}
      title={title}
      namespace={namespace}
    />
  </div>
);

SkyBanner.propTypes = {
  ...SkyBannerPropTypes,
};

export default withComponentHandler(SkyBanner, { componentName: 'SkyBanner' });
