import { loadable } from 'nordic/lazy';

import { LAYOUTS } from '../../../constants';

const GridColumn = loadable(
  async () =>
    /* webpackChunkName: "chunk-layout-grid-column" */
    import(`../components/columns/grid-column`),
  {
    resolveComponent: (components) => components.GridColumnLayout,
  },
);

const MapLayout = loadable(async () =>
  /* webpackChunkName: "chunk-layout-map" */
  import(`../../../components/layout/columns/map`),
);

const OneColumnLayout = loadable(
  async () =>
    /* webpackChunkName: "chunk-layout-one-column" */
    import(`../components/columns/one-column`),
  {
    resolveComponent: (components) => components.OneColumnLayout,
  },
);

const layoutByName = {
  [LAYOUTS.TYPE_GRID]: GridColumn,
  [LAYOUTS.TYPE_MAP]: MapLayout,
};

/**
 * Gets the layout corresponding to the provided name.
 *
 * @param {string} name - The name of the layout.
 * @returns {React.Component} The corresponding layout component.
 */
export const getLayoutByName = (name) => layoutByName[name] ?? OneColumnLayout;
