import { useEffect, useMemo, useState } from 'react';

import classnames from 'classnames';

import { useIsFade } from '../../../components/context/layout-fade';
import { useStaticProps } from '../../../components/context/static-props';
import useWindowDimensions from '../../../hooks/use-window-dimensions';
import { layoutNamespace, shopsNamespace } from '../constants';
import { useResults } from './use-results';
import { DEVICE_TYPES } from '../../../constants';

export const useLayoutManager = () => {
  const { results, polycard_context, name, isPolycard, isShops } = useResults();

  const isFade = useIsFade();

  const { width } = useWindowDimensions();
  const { lowEnd, visualId, deviceType, country, vertical } = useStaticProps();

  const resultLength = deviceType === DEVICE_TYPES.TABLET ? 2 : 3;

  const [useItemsPerRow, setUseItemsPerRow] = useState(resultLength);

  const options = useMemo(() => ({ lowEnd, verboseLabels: true, cardSupportsLinks: true }), [lowEnd]);

  useEffect(() => {
    setUseItemsPerRow(width < 1024 ? 2 : 3);
  }, [width]);

  const className = classnames(
    layoutNamespace,
    { [`${layoutNamespace}--${name}`]: name },
    { [`${layoutNamespace}--${visualId}`]: visualId },
    { [`${layoutNamespace}--fade`]: isFade },
    { [shopsNamespace]: isShops },
  );

  return {
    className,
    country,
    deviceType,
    isPolycard,
    isShops,
    name,
    options,
    polycard_context,
    results,
    useItemsPerRow,
    vertical,
  };
};
