import React from 'react';

import classnames from 'classnames';

import { useSearch } from '../../../../hooks/context';
import { namespace, searchResultPropTypes } from './constants';
import { withComponentHandler } from '../../../../hocs/with-component-handler';

/**
 * SearchResult component.
 *
 * @param {Object} props - Component props.
 * @param {string} [props.text=''] - Text to display in the search result.
 * @returns {JSX.Element} The rendered component.
 */

const SearchResult = ({ text = '' }) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    <div className={classnames(namespace, { shops__result: isShops })}>
      <span className={classnames(`${namespace}__quantity-results`, { 'shops-custom-secondary-font': isShops })}>
        {text}
      </span>
    </div>
  );
};

SearchResult.propTypes = {
  ...searchResultPropTypes,
};

export default withComponentHandler(SearchResult, { componentName: 'SearchResult' });
