import React from 'react';

import { loadable } from 'nordic/lazy';

import useFooterDisclaimer from './use-footer-disclaimer';
import { withComponentHandler } from '../../hocs/with-component-handler';
/**
 * @typedef {Object} FooterDisclaimerProps
 * @property {string} text - El texto del disclaimer del footer.
 * @property {string} url - La URL asociada al disclaimer del footer.
 */

/**
 * Componente que carga dinámicamente el disclaimer del footer.
 *
 * @returns {React.ReactNode|null} Un nodo React que contiene el disclaimer del footer o `null` si no hay propiedades disponibles.
 */
const ChunkFooterDisclaimer = () => {
  const footerDisclaimerProps = useFooterDisclaimer();
  const FooterDisclaimer = loadable(
    /* webpackChunkName: "chunk-footer-disclaimer" */ async () => import('./footer-disclaimer'),
  );

  return footerDisclaimerProps ? <FooterDisclaimer {...footerDisclaimerProps} /> : null;
};

export default withComponentHandler(ChunkFooterDisclaimer, { componentName: 'ChunkFooterDisclaimer' });
