export const CARD_DATA_GROUPS = {
  PRICE: 'price',
  BRAND_DISCOVERABILITY: 'brand',
  INSTALLMENTS: 'installments',
  SHIPPING: 'shipping',
  TITLE: 'title',
  REVIEWS: 'reviews',
  LOCATION: 'location',
  ATTRIBUTES: 'attributes',
  DETAILS: 'details',
  PDS: 'pds',
  POSSESSION: 'possession',
  PRODUCT_ATTRIBUTES: 'product-attributes',
  VARIATIONS_TEXT: 'variations-text',
  ADD_TO_CART: 'add-to-cart',
  MEDIA_TAG: 'media-tag',
  HIGHLIGHT: 'highlight',
  REBATES: 'rebates',
  VERTICAL_HIGHLIGHT: 'vertical-highlight',
  RENTAL_TYPE: 'rental-type',
  VARIATIONS_PICKER: 'variations-picker-color',
  AVAILABLE_UNITS: 'available-units',
  ONLINE_VISIT_REQUEST: 'online-visit-request',
};

export const CAROUSEL_SETTINGS = {
  dots: false,
  arrowsVisible: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  infinite: false,
};

export const GENERIC_KEY_PREFIXES = {
  TOP_COLUMN: 'TOP',
  BOTTOM_COLUMN: 'BOTTOM',
  LEFT_COLUMN: 'LEFT',
  RIGHT_COLUMN: 'RIGHT',
  MODAL_COMPONENTS: 'MODAL_COMPONENTS',
};

export const ESTIMATED_CONTENT_WIDTHS = {
  mobile: {
    stack: 180,
    mosaic: 138,
    gallery: 296,
  },
  desktop: {
    stack: 630,
    gallery: 200,
  },
  DEFAULT: 250,
};

export const CONTENT_KEYS = {
  PLATFORM: {
    SHOPS: 'SHOPS',
    DEFAULT: 'DEFAULT',
  },
};

export const namespace = 'ui-search-result';
export const layoutNamespace = 'ui-search-layout';
export const shopsNamespace = 'shops__layout';
export const namespaceCompats = 'ui-compats-wrapper';
