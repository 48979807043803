import { isEmpty } from 'lodash';

import { useCurrentLayout } from '../../../components/context/layout';
import { useSearch } from '../../../hooks/context';

export const useResults = () => {
  const { results, polycard_context, layout_options, shops } = useSearch();

  const isShops = Boolean(shops);
  const currentLayout = useCurrentLayout();
  const name = currentLayout === undefined ? layout_options.current : currentLayout.id;
  const isPolycard = !isEmpty(polycard_context);

  return { currentLayout, results, polycard_context, isPolycard, layout_options, name, isShops };
};
