import React, { useMemo } from 'react';

import { useLayoutManager } from './hooks/use-layout-manager.hook';
import { getLayoutByName } from './utils/get-layout';
import { withComponentHandler } from '../../hocs/with-component-handler';

/**
 * @typedef {Object} LayoutManagerProps
 */

/**
 * LayoutManager component manages the layout rendering based on the current state.
 *
 * @returns {JSX.Element} The rendered layout component.
 */
export const LayoutManagerComponent = () => {
  const {
    country,
    deviceType,
    isShops,
    name,
    options,
    polycard_context,
    results,
    useItemsPerRow,
    vertical,
    className,
    isPolycard,
  } = useLayoutManager();

  const LayoutComponent = useMemo(() => getLayoutByName(name), [name]);

  return (
    <LayoutComponent
      layout={name}
      polycard_context={polycard_context}
      deviceType={deviceType}
      cols={useItemsPerRow}
      results={results}
      isShops={isShops}
      country={country}
      options={options}
      vertical={vertical}
      className={className}
      isPolycard={isPolycard}
    />
  );
};

export const LayoutManager = withComponentHandler(LayoutManagerComponent, { componentName: 'LayoutManager' });
