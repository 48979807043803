import React from 'react';
import { shape, string } from 'prop-types';

import classnames from 'classnames';
import { Image } from 'nordic/image';
import { Tooltip } from '@andes/tooltip';

import { FilterLink } from '../../link';
import ProcessedDescription from './components/processed-description';
import { withComponentHandler } from '../../../hocs/with-component-handler';

const namespace = 'ui-search-filter-official-store';

/**
 * @typedef {Object} FilterOfficialStoreProps
 * @property {string} [id] - El identificador del filtro.
 * @property {string} [name] - El nombre del filtro.
 * @property {Object[]} expanded_values - Los valores expandidos asociados al filtro.
 * @property {string} expanded_values[].id - El identificador único del valor expandido.
 * @property {string} expanded_values[].name - El nombre del valor expandido.
 * @property {string} expanded_values[].url - El enlace asociado al valor expandido.
 * @property {string} [expanded_values[].logo_url] - La URL del logo asociado al valor expandido (opcional).
 * @property {string} [className] - Las clases CSS adicionales para el componente (opcional).
 */

const FilterOfficialStore = ({ id = '', name = '', expanded_values = null, className = '' }) => (
  <div className={classnames(className, `${namespace} ${namespace}-${id}`)}>
    {expanded_values.map((value) => (
      <div className={`${namespace}__container`} key={`${id}-${value.id}`}>
        <FilterLink title={value.name} href={value.url}>
          <Tooltip content={<ProcessedDescription {...value} />} side="bottom" className={`${namespace}__tooltip`}>
            <Image alt={name} className={`${namespace}__image`} src={value.logo_url} lowEnd />
          </Tooltip>
        </FilterLink>
      </div>
    ))}
  </div>
);

FilterOfficialStore.propTypes = {
  className: string,
  expanded_values: shape({}),
  id: string,
  name: string,
};

export default withComponentHandler(FilterOfficialStore, { componentName: 'FilterOfficialStore' });
