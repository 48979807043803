import React from 'react';

import useTooltip from '../../../../../hooks/use-tooltip';
import { FilterTooltip } from '../../filters-tooltip/mobile';
import { getFilterTooltipKey } from '../../filters-tooltip/helpers';
import { trackClickEvent } from '../../../../../components/map/track';
import { namespaceToolbal as namespace, MapLinkMobileProps, ICONS } from '../constants';
import { withComponentHandler } from '../../../../../hocs/with-component-handler';

/**
 * MapLinkMobile component renders a link with an optional tooltip.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.map_link - The map link data.
 * @param {string} [props.map_link.analytics_track_event=null] - The analytics track event.
 * @param {string} [props.map_link.melidata_track_value=null] - The melidata track value.
 * @param {string} props.map_link.label - The label for the link.
 * @param {string} props.map_link.url - The URL for the link.
 * @param {string} [props.map_link.tooltip=null] - The tooltip content.
 * @param {string} props.map_link.type - The type of the icon.
 * @returns {JSX.Element} The rendered component.
 */

const MapLinkMobile = ({
  map_link: { analytics_track_event = null, melidata_track_value = null, label, url, tooltip = null, type },
}) => {
  const tooltipKey = getFilterTooltipKey(tooltip);
  const [tooltipStatus, closeTooltip] = useTooltip(tooltipKey);

  const handleClick = () => {
    if (analytics_track_event && melidata_track_value) {
      trackClickEvent(analytics_track_event, melidata_track_value);
    }

    closeTooltip();
  };

  const { IconComponent, className } = ICONS[type] || ICONS.MAP_LINK;

  return (
    <li className={`${namespace}__action`}>
      <a className={`${namespace}__link`} href={url} onClick={handleClick}>
        <div className={`${namespace}__icon`}>
          <IconComponent className={`${namespace}__icon--${className}`} />
        </div>
        {label}
      </a>
      <FilterTooltip
        className={`${namespace}__tooltip`}
        mask
        maskClosable={false}
        onClose={closeTooltip}
        side="bottomLeft"
        tooltip={tooltip}
        tooltipStatus={tooltipStatus}
      >
        <div className={`${namespace}__tooltip-trigger`} />
      </FilterTooltip>
    </li>
  );
};

MapLinkMobile.propTypes = {
  ...MapLinkMobileProps,
};

export default withComponentHandler(MapLinkMobile, { componentName: 'MapLinkMobile' });
