import React, { useState } from 'react';

import classnames from 'classnames';
import { Button } from '@andes/button';

import ModalContentNotFound from './ModalContentNotFound';
import useFilterValuesMultiSelect from '../../../../../hooks/use-filter-values-multiselect';
import { getGrouper } from '../utils/getGroupers';
import { getContentModifier } from '../utils/getContentModifier';
import { getSearchBar } from '../utils/getSearchBar';
import { namespace } from '../constants';
import { valuesPropTypes } from '../../filters/prop-types';
import { withComponentHandler } from '../../../../../hocs/with-component-handler';

/**
 * SearchModal component renders a modal with a search bar and a list of filterable values.
 *
 * @param {Object} props - Component props
 * @param {string} props.id - The ID of the filter
 * @param {string} props.type - The type of the filter
 * @param {Array} props.values - The list of values to filter
 * @param {string} props.title - The title of the modal
 * @param {Function} props.contentRenderer - Function to render each filter value
 * @param {Object} props.labels - Labels for the modal
 * @param {Object} props.multi_selection - Multi-selection configuration
 * @returns {JSX.Element} The rendered component
 */

const SearchModal = ({ id, type, values, title, contentRenderer, labels, multi_selection }) => {
  const [filteredValues, setFilteredValues] = useState(values);
  const [scrolled, setScrolled] = useState(false);
  const [canScroll, setCanScroll] = useState(true);
  const { handleSelectValue, isValueSelected, handleSubmit, showSubmit } = useFilterValuesMultiSelect({
    filterId: id,
    multiSelection: multi_selection,
    allValues: filteredValues,
    updateAllValues: setFilteredValues,
  });

  const labelNotFound = labels?.modal_not_found_message;
  const labelPlaceholder = labels?.modal_place_holder;

  const handleChange = (e) => {
    const filter = values.filter(
      (value) =>
        value.name
          .toLowerCase()
          .normalize('NFD')
          .replace(/[\u0300-\u036f]/g, '')
          .indexOf(e.target.value.toLowerCase()) !== -1,
    );

    setFilteredValues(filter);
  };

  const handleScroll = (event) => {
    setScrolled(event.target.scrollTop > 0);
    setCanScroll(event.target.scrollTop + event.target.clientHeight + 10 <= event.target.scrollHeight);
  };

  const renderFilterValue = (filterValue) =>
    contentRenderer(
      {
        ...filterValue,
        handleSelectValue,
        isSelected: isValueSelected(filterValue),
      },
      title,
    );

  const grouper = getGrouper(type);

  return (
    <div
      id="modal"
      className={classnames(namespace, {
        [`${namespace}--scrolled`]: scrolled,
        [`${namespace}__scroll-fade`]: canScroll,
      })}
    >
      <div className={`${namespace}__header`}>{getSearchBar(type, labelPlaceholder, handleChange)}</div>
      {filteredValues.length === 0 ? (
        <ModalContentNotFound labelNotFound={labelNotFound} />
      ) : (
        <div onScroll={handleScroll} className={`${namespace}-${getContentModifier(type, id)}`}>
          {grouper ? grouper(filteredValues, renderFilterValue) : filteredValues.map(renderFilterValue)}
        </div>
      )}
      {showSubmit && (
        <div className={`${namespace}__footer`}>
          <Button hierarchy="loud" size="medium" className={`${namespace}__submit-button`} onClick={handleSubmit}>
            {multi_selection.submit_text}
          </Button>
        </div>
      )}
    </div>
  );
};

SearchModal.propTypes = {
  ...valuesPropTypes,
};

export default withComponentHandler(SearchModal, { componentName: 'SearchModal' });
