import React from 'react';

import { Switch } from '@andes/switch';

import SwitchFilterLabel from './components/switch-filter-label';
import { SWITCH_FILTERS_PROPS_TYPES } from './constants/prop-types';
import useLabelBuilderProps from './hooks/use-label-builder-props';
import { withComponentHandler } from '../../hocs/with-component-handler';

/**
 * @typedef {Object} SWITCH_FILTERS_PROPS_TYPES
 * @property {Object} highlight - Propiedades del resaltado.
 * @property {Object} highlight.icon - Icono del resaltado.
 * @property {string} highlight.icon.id - ID del icono.
 * @property {string} highlight.icon.color - Color del icono.
 * @property {string} highlight.icon.highlight_position - Posición del icono resaltado.
 * @property {Object} highlight.label - Etiqueta del resaltado.
 * @property {string} highlight.label.text - Texto de la etiqueta.
 * @property {string} highlight.label.color - Color de la etiqueta.
 * @property {string} highlight.label.background - Fondo de la etiqueta.
 * @property {number} highlight.label.size - Tamaño de la etiqueta.
 * @property {number} highlight.label.weight - Peso de la etiqueta.
 * @property {string} highlight.label.style - Estilo de la etiqueta.
 * @property {string} htmlForIDValue - Valor del ID para el atributo htmlFor.
 * @property {Object} label - Propiedades de la etiqueta.
 * @property {string} label.text - Texto de la etiqueta.
 * @property {Object} title - Propiedades del título.
 * @property {TEXT} title.text - Texto del título.
 * @property {ICON} title.icon - Icono del título.
 * @property {Object} title.title_suffix - Sufijo del título.
 * @property {TEXT} title.title_suffix.text - Texto del sufijo del título.
 * @property {ICON} title.title_suffix.icon - Icono del sufijo del título.
 * @property {Object} subtitle - Propiedades del subtítulo.
 * @property {TEXT} subtitle.text - Texto del subtítulo.
 * @property {ICON} subtitle.icon - Icono del subtítulo.
 * @property {AMOUNT} subtitle.amount - Cantidad del subtítulo.
 * @property {string} namespace - Espacio de nombres.
 * @property {string} srLabel - Etiqueta de SR.
 * @property {Object} style - Estilo.
 * @property {string} style.width - Ancho.
 * @property {string} style.height - Altura.
 */

const SwitchFilter = (props) => {
  const { id, className, checked, title, subtitle, labelPosition, onChange, onClick, disabled, highlight, label } =
    props;

  const { titleLabelBuilderProps, subtitleLabelBuilderProps, highlightLabelBuilderProps, labelLabelBuilderProps } =
    useLabelBuilderProps({ title, subtitle, highlight, label });

  return (
    <Switch
      id={id}
      className={className}
      checked={checked}
      labelPosition={labelPosition}
      onChange={onChange}
      onClick={onClick}
      disabled={disabled}
      label={
        <SwitchFilterLabel
          title={{ ...title, titleLabelBuilderProps }}
          subtitle={{ ...subtitle, subtitleLabelBuilderProps }}
          highlight={{ ...highlight, highlightLabelBuilderProps }}
          label={{ ...label, labelLabelBuilderProps }}
        />
      }
    />
  );
};

SwitchFilter.propTypes = {
  ...SWITCH_FILTERS_PROPS_TYPES,
};

export default withComponentHandler(SwitchFilter, { componentName: 'SwitchFilter' });
