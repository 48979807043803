import React from 'react';
import { node, string, func, bool, shape } from 'prop-types';

import classnames from 'classnames';
import { Modal as AndesModal } from '@andes/modal';

import Link from '../../link/link';
import { useSearch } from '../../../hooks/context';
import { withComponentHandler } from '../../../hocs/with-component-handler';

const namespace = 'ui-search-modal';

/**
 * @typedef {Object} ModalProps
 * @property {Object} actions - Acciones del modal.
 * @property {React.ReactNode} actions.buttonFilled - Botón lleno.
 * @property {React.ReactNode} children - Contenido del modal.
 * @property {string} [className] - Clase CSS adicional para el modal.
 * @property {string} [closeText] - Texto del botón de cerrar.
 * @property {React.ReactNode} [componentAsLabel] - Componente como etiqueta.
 * @property {boolean} [focusTab] - Indica si se debe enfocar la pestaña.
 * @property {string} [headerSuffix] - Sufijo del encabezado.
 * @property {Function} [hideModal] - Función para ocultar el modal.
 * @property {React.ReactNode} [icon] - Icono del modal.
 * @property {boolean} [isOpen] - Indica si el modal está abierto.
 * @property {string} label - Etiqueta del modal.
 * @property {string} [modalClassName] - Clase CSS específica del modal.
 * @property {string} [modalTitle] - Título del modal.
 * @property {string} [modalUrl] - URL del modal.
 * @property {Function} [openModal] - Función para abrir el modal.
 * @property {string} [type] - Tipo de modal.
 * @property {string} [url] - URL del modal.
 * @property {boolean} [withLink] - Indica si el modal tiene un enlace.
 */

/**
 * Componente Modal.
 *
 * @param {ModalProps} props - Propiedades del componente.
 * @returns {JSX.Element} El componente Modal.
 */

const Modal = ({
  children,
  label,
  actions = null,
  className = '',
  closeText = '',
  componentAsLabel = null,
  headerSuffix = '',
  icon = null,
  modalClassName = '',
  modalTitle = '',
  modalUrl = '',
  url = '',
  isOpen = false,
  openModal = null,
  hideModal = null,
  type = 'full',
  withLink = false,
  focusTab = false,
  ...rest
}) => {
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  return (
    <>
      {withLink && (
        <Link
          className={classnames(`${namespace}__link`, className)}
          href={focusTab && !url ? ' ' : url}
          onClick={openModal}
          title={label}
        >
          {icon && <div className={classnames(className, { [`${namespace}__icon`]: !componentAsLabel })}>{icon}</div>}
          {label || componentAsLabel}
        </Link>
      )}
      <AndesModal
        actions={actions}
        headerSuffix={headerSuffix}
        closeButtonSrLabel="close"
        url={modalUrl}
        type={type}
        className={classnames(namespace, modalClassName, className, { shops__modal: isShops })}
        open={isOpen}
        onClose={hideModal}
        title={modalTitle}
        closeText={closeText}
        showCloseButton
        {...rest}
      >
        {children}
      </AndesModal>
    </>
  );
};

Modal.propTypes = {
  actions: shape({
    buttonFilled: node,
  }),
  children: node.isRequired,
  className: string,
  closeText: string,
  componentAsLabel: node,
  focusTab: bool,
  headerSuffix: string,
  hideModal: func,
  icon: node,
  isOpen: bool,
  label: string.isRequired,
  modalClassName: string,
  modalTitle: string,
  modalUrl: string,
  openModal: func,
  type: string,
  url: string,
  withLink: bool,
};

export default withComponentHandler(Modal, { componentName: 'Modal' });
