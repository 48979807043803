import React from 'react';
import { bool, shape, string } from 'prop-types';

import sources from '../constants/component-types';
import resources from './resources-components';
import { SEARCH_API_BASE_PATH } from '../../../../services/constants/paths';
import { withComponentHandler } from '../../../hocs/with-component-handler';

/**
 * @typedef {Object} EshopFactoryProps
 * @property {string} component - El identificador del componente a renderizar.
 * @property {Object} properties - Las propiedades adicionales del componente.
 * @property {boolean} hidden - Indica si el componente debe estar oculto (opcional).
 * @property {React.ReactNode} children - Los componentes hijos que serán procesados (opcional).
 * @property {string} siteId - El identificador del sitio.
 */

/**
 * Componente que procesa y renderiza dinámicamente otros componentes utilizando `React.createElement`.
 *
 * @param {EshopFactoryProps} props - Las propiedades del componente.
 * @returns {React.ReactNode|null} El componente renderizado o `null` si está oculto o no tiene fuente.
 */
const EshopFactory = ({ component, properties, children, hidden, siteId }) => {
  const source = resources[component];

  if (hidden || !source) {
    return null;
  }

  const isProfileHeader = component === sources.PROFILE_HEADER;

  const { is_repurchase = false, ...rest } = properties;

  const props = isProfileHeader
    ? { ...properties, baseURL: `${SEARCH_API_BASE_PATH}/`, siteId }
    : { ...rest, isRepurchase: is_repurchase, siteId };

  const params =
    children?.length > 0
      ? [source, props, children?.map((ch) => <EshopFactory {...ch} key={ch?.id} />)]
      : [source, props];

  if (isProfileHeader) {
    return <div className="wrapper-sticky">{React.createElement(...params)}</div>;
  }

  return React.createElement(...params);
};

EshopFactory.propTypes = {
  children: shape(EshopFactory).isRequired,
  component: string.isRequired,
  hidden: bool.isRequired,
  properties: shape.isRequired,
  siteId: string.isRequired,
};

export default withComponentHandler(EshopFactory, { componentName: 'EshopFactory' });
