import React from 'react';
import { arrayOf, bool, shape, string } from 'prop-types';

import classnames from 'classnames';

import LabelBuilder from '../../../label-builder';
import { useSearch } from '../../../../hooks/context';
import BreadcrumbItem from './components/breadcrumb-item';
import { BreadcrumbSchema, namespace } from './constants';
import { withComponentHandler } from '../../../../hocs/with-component-handler';

/**
 * Breadcrumb component.
 *
 * @typedef {Object} BreadcrumbProps
 * @property {string} h1 - The H1 text.
 * @property {string} html_tag - The HTML tag to use.
 * @property {boolean} isMobile - Flag indicating if the view is mobile.
 * @property {Object} label - The label object.
 * @property {string} label.text - The text of the label.
 * @property {string} label.prefix - The prefix of the label.
 * @property {string} link_h1 - The H1 link.
 * @property {Array<Object>} nodes - The breadcrumb nodes.
 * @property {string} nodes.text - The text of the node.
 * @property {string} nodes.url - The URL of the node.
 * @property {boolean} show_h2 - Flag indicating if H2 should be shown.
 * @property {string} text_link - The text link.
 * @property {Array<Object>} values - The values array.
 * @property {string} values.key - The key of the value.
 *
 * @param {BreadcrumbProps} props - The properties for the Breadcrumb component.
 * @returns {JSX.Element} The rendered Breadcrumb component.
 */

const Breadcrumb = ({
  label: initialLabel,
  nodes: initialNodes,
  h1 = '',
  html_tag = '',
  isMobile = false,
  link_h1 = '',
  show_h2 = false,
  text_link = '',
  values = null,
}) => {
  const label = initialLabel || { text: '' };
  const nodes = initialNodes || [];
  const { shops } = useSearch();
  const isShops = Boolean(shops);
  const text = label?.prefix ? `${label.prefix} ${label.text}` : label?.text || h1;
  const textLink = text_link || link_h1;
  const tag = show_h2 ? 'h2' : 'h1';
  const HtmlTag = html_tag || tag;

  const classNameTitle = classnames(`${namespace}__title`, `${values ? 'max-width' : ''}`, {
    'shops-custom-primary-font': isShops,
  });

  return (
    <div className={classnames(namespace, { shops__breadcrumb: isShops })}>
      {!!nodes.length && (
        <ol className="andes-breadcrumb" itemType={BreadcrumbSchema} itemScope>
          {nodes.map((node, index) => (
            <BreadcrumbItem {...node} isLast={index === nodes.length - 1} position={index + 1} key={node?.text} />
          ))}
        </ol>
      )}
      {!isMobile &&
        (textLink ? (
          <HtmlTag className={classNameTitle}>
            <a className={`${namespace}__link`} href={textLink}>
              {text}
            </a>
          </HtmlTag>
        ) : (
          <LabelBuilder
            text={text}
            values={
              values?.map((value) => ({
                key: value?.key,
                type: value?.type || 'ICON',
                icon_id: value?.iconId || value?.key,
                className: value?.className || 'full-icon-breadcrumb',
              })) || []
            }
            html_tag={HtmlTag}
            class_name={classNameTitle}
          />
        ))}
    </div>
  );
};

Breadcrumb.propTypes = {
  h1: string,
  html_tag: string,
  isMobile: bool,
  label: shape({
    text: string,
    prefix: string,
  }),
  link_h1: string,
  nodes: arrayOf(
    shape({
      text: string.isRequired,
      url: string.isRequired,
    }),
  ),
  show_h2: bool,
  text_link: string,
  values: arrayOf(
    shape({
      key: string,
    }),
  ),
};

const WrapperBreadcrumb = withComponentHandler(Breadcrumb, { componentName: 'Breadcrumb' });

export default WrapperBreadcrumb;
