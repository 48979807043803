import React from 'react';

import classnames from 'classnames';
import { Dropdown, DropdownItem } from '@andes/dropdown';

import { FilterSortPropTypes, namespace } from './constants';
import { useSearch } from '../../../hooks/context';
import { useDropdown } from './hooks/use-dropdown';
import { withComponentHandler } from '../../../hocs/with-component-handler';

/**
 * @typedef {Object} SortOption
 * @property {string} id - El identificador de la opción de ordenamiento.
 * @property {string} name - El nombre de la opción de ordenamiento.
 * @property {boolean} selected - Indica si la opción está seleccionada.
 */

const FilterSort = (props) => {
  const { sorts = [], has_disclaimer = false } = props;
  const { shops } = useSearch();
  const isShops = Boolean(shops);

  const { itemSelected, onSelectItem } = useDropdown(sorts);

  if (!sorts && !sorts.length) {
    return null;
  }

  return sorts.length > 1 ? (
    <div
      className={classnames(namespace, {
        [`${namespace}--with-disclaimer`]: has_disclaimer,
        'shops__sort-filter': isShops,
      })}
    >
      <Dropdown
        label={itemSelected?.name}
        value={itemSelected?.id}
        size="small"
        menuAlignment="bottom"
        onChange={onSelectItem}
        className={`${namespace}__dropdown`}
      >
        {sorts.map(({ id, name, selected }) => (
          <DropdownItem key={`${id}-${name}`} value={id} title={name} selected={selected} />
        ))}
      </Dropdown>
    </div>
  ) : (
    <span className={`${namespace}-values`}>{sorts[0]?.name}</span>
  );
};

/**
 * Prop types
 */
FilterSort.propTypes = FilterSortPropTypes;

export default withComponentHandler(FilterSort, { componentName: 'FilterSort' });
