import React from 'react';

import KeywordSummary from '@seo-frontend-components/keyword-summary-mobile';

import { useStaticProps } from '../../../components/context/static-props';
import {
  namespaceDesktop,
  expandablePropsSize,
  expandablePropsColor,
  expandablePropsDefaultHeightDesktop,
  seoInfoImageTextBoxInterventionPropTypes,
  preloadImage,
  charCountToExpand,
} from './constants';
import { withComponentHandler } from '../../../hocs/with-component-handler';
/**
 * @typedef {Object} SeoInfoImageTextBoxInterventionProps
 * @property {string} title - El título de la intervención.
 * @property {string} description - La descripción de la intervención.
 * @property {string} alt_image - El texto alternativo para la imagen.
 * @property {string} image_url - La URL de la imagen asociada a la intervención.
 * @property {string} expansion_text - El texto para expandir la descripción.
 * @property {string} contraction_text - El texto para contraer la descripción.
 */

/**
 * Componente que renderiza una intervención SEO con imagen, texto y funcionalidad de expansión/contracción.
 *
 * @param {SeoInfoImageTextBoxInterventionProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene la intervención SEO.
 */
const SeoInfoImageTextBoxIntervention = ({
  title,
  description,
  alt_image,
  image_url,
  expansion_text,
  contraction_text,
}) => {
  const { jsDisabled = false, country } = useStaticProps();
  const { andesLocale } = country;

  const summaryProps = {
    jsDisabled,
    title,
    description,
    image: {
      altImage: alt_image,
      imageUrl: image_url,
      fallbackImageUrl: image_url,
      preload: preloadImage,
    },
    expandableProps: {
      expansionText: expansion_text,
      contractionText: contraction_text,
      size: expandablePropsSize,
      defaultHeight: expandablePropsDefaultHeightDesktop,
      color: expandablePropsColor,
    },
    locale: andesLocale,
    charCountToExpand,
  };

  return (
    <div className={`${namespaceDesktop}__container`}>
      <KeywordSummary {...summaryProps} />
    </div>
  );
};

SeoInfoImageTextBoxIntervention.propTypes = {
  ...seoInfoImageTextBoxInterventionPropTypes,
};

export default withComponentHandler(SeoInfoImageTextBoxIntervention, {
  componentName: 'SeoInfoImageTextBoxIntervention',
});
