import React from 'react';

import IconMap from '../../../../icons/sources/map';
import FilterTooltip from '../../filters-tooltip/desktop';
import { trackClickEvent } from '../../../../../components/map/track';
import { namespace, MapLinkDesktopProps } from '../constants';
import { withComponentHandler } from '../../../../../hocs/with-component-handler';

/**
 * Componente MapLinkDesktop
 *
 * @param {Object} props - Propiedades del componente
 * @param {string} props.url - URL del enlace
 * @param {string} props.label - Etiqueta del enlace
 * @param {string} [props.melidata_track_value=null] - Valor de seguimiento de Melidata
 * @param {string} [props.analytics_track_event=null] - Evento de seguimiento de Analytics
 * @param {string} [props.tooltip=null] - Texto del tooltip
 * @param {boolean} [props.hasIcon=true] - Indica si se debe mostrar el ícono
 * @returns {JSX.Element} - Elemento JSX del componente
 */

const MapLinkDesktop = ({
  url,
  label,
  melidata_track_value = null,
  analytics_track_event = null,
  tooltip = null,
  hasIcon = true,
}) => {
  const handleClick = () => {
    if (analytics_track_event && melidata_track_value) {
      trackClickEvent(analytics_track_event, melidata_track_value);
    }
  };

  return (
    <section>
      <a className={`${namespace}__link`} href={url} onClick={handleClick}>
        <FilterTooltip tooltip={tooltip}>
          {hasIcon && <IconMap className={`${namespace}__icon-map`} />}
          <span>{label}</span>
        </FilterTooltip>
      </a>
    </section>
  );
};

MapLinkDesktop.propTypes = {
  ...MapLinkDesktopProps,
};

export default withComponentHandler(MapLinkDesktop, { componentName: 'MapLinkDesktop' });
