import React from 'react';
import { bool, func, number, shape, string } from 'prop-types';

import { ARROW_DIRECTIONS } from './utils/constants';
import { ArrowButton, ContentPagination, NodePagination } from './components';
import { withComponentHandler } from '../../hocs/with-component-handler';

const namespace = 'ui-search-map-pagination';

const { BACK, NEXT } = ARROW_DIRECTIONS;

/**
 * @typedef {Object} PagePropTypes
 * @property {boolean} show - Indica si la página está disponible.
 * @property {string} title - El título de la página.
 * @property {string} [href] - El enlace de la página.
 * @property {function} onClick - La función que se ejecuta al hacer clic en la página.
 */

/**
 * Componente de paginación que muestra botones de navegación y la página actual.
 *
 * @param {Object} props - Las propiedades del componente.
 * @param {number} props.currentPage - El número de la página actual.
 * @param {PagePropTypes} props.previousPage - Las propiedades de la página anterior.
 * @param {PagePropTypes} props.nextPage - Las propiedades de la página siguiente.
 * @returns {JSX.Element} El componente de paginación.
 */

const Pagination = ({ currentPage, previousPage, nextPage }) => (
  <ContentPagination className={namespace}>
    <ArrowButton
      direction={BACK}
      disabled={!previousPage.show}
      title={previousPage.title}
      href={previousPage.href}
      handleChange={previousPage.onClick}
    />

    <NodePagination isActualPage value={currentPage} />

    <ArrowButton
      direction={NEXT}
      disabled={!nextPage.show}
      title={nextPage.title}
      href={nextPage.href}
      handleChange={nextPage.onClick}
    />
  </ContentPagination>
);

const PAGE_PROPTYPES = shape({
  show: bool.isRequired,
  title: string.isRequired,
  href: string,
  onClick: func.isRequired,
}).isRequired;

Pagination.propTypes = {
  currentPage: number.isRequired,
  nextPage: PAGE_PROPTYPES,
  previousPage: PAGE_PROPTYPES,
};

export default withComponentHandler(Pagination, { componentName: 'Pagination', isCritical: true });
