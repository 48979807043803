import React from 'react';
import { string } from 'prop-types';

import classnames from 'classnames';

import { UI_SEARCH_FILTER_COLORS as namespace } from '../../../constants';
import { withComponentHandler } from '../../../hocs/with-component-handler';

/**
 * @typedef {Object} FiltersColorsProps
 * @property {string} [id] - El identificador del filtro de colores.
 * @property {string} [className] - Las clases CSS adicionales para el componente.
 */

/**
 * Componente que renderiza un contenedor para filtros de colores.
 *
 * @param {FiltersColorsProps} props - Las propiedades del componente.
 * @returns {React.ReactNode} Un nodo React que contiene el contenedor del filtro de colores.
 */
const FiltersColors = ({ id = '', className = '' }) => (
  <div className={classnames(className, `${namespace}`, `${namespace}-${id}`)} />
);

FiltersColors.propTypes = {
  className: string,
  id: string,
};

export default withComponentHandler(FiltersColors, { componentName: 'FiltersColors' });
